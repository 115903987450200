import { createSlice } from "@reduxjs/toolkit";
import { Modal } from "antd";
import axiosApp from "../../api";

// our slice
export const itemSlices = createSlice({
  name: "contact",
  initialState: {
    loading: false,
    data:  null,
  },
  reducers: {
    setRequest: (state, action) => {
      state.loading = action.payload.type;
    },
    setFinally: (state) => {
      state.loading = false;
    },
  },
});

// export the actions
export const { setRequest, setSuccess, setFinally } = itemSlices.actions;

// export the default reducer
export default itemSlices.reducer;

export const contactPost = ({values , callback}) => async (dispatch) => {
  try {
    dispatch(
      setRequest({
        type: "post",
      })
    );
    await axiosApp.post(`/tickets/store` , values );
    callback()
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error?.response?.data?.message,
    });
    } finally {
    dispatch(setFinally());
  }
};
