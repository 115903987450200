import './App.css';
import { ConfigProvider } from 'antd';
import Layout from './components/Layout/Layout'
import "./assets/styles/main.scss";

function App() {

  
  return (
      <ConfigProvider
        // direction={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
        theme={{
          token: {
            colorPrimary: "#50746C",
            fontFamily: "Tajawal",
          },
        }}
      >
      <Layout />
    </ConfigProvider>
  );
}

export default App;
