import { createSlice } from "@reduxjs/toolkit";
import { Modal, notification } from "antd";
import axiosApp from "../../api";

// initial state

// our slice
export const itemSlice = createSlice({
  name: "login",
  initialState: {
    loading: false,
    error: false,
    user:  !!localStorage?.getItem("user") ? JSON.parse(localStorage?.getItem("user"))  : {},
  },
  reducers: {
    setRequest: (state) => {
      state.loading = true;
    },
    setSuccess: (state, { payload }) => {
      state.loading = false;
      state.user = payload;
      
    },
    setFinally: (state) => {
      state.loading = false;
    },
  },
});

// export the actions
export const { setRequest, setSuccess, setFinally } = itemSlice.actions;

// export the default reducer
export default itemSlice.reducer;

export const login = (values) => async (dispatch) => {
  try {
    dispatch(setRequest());
    const response = await axiosApp.post(`/auth/login`, values);
    dispatch(setSuccess(response.data));
    localStorage.setItem("user", JSON.stringify(response.data.data));
    // notification.success({
    //   message: "تم تسيجيل دخولك بنجاح",
    //   duration: 2,
    //   placement:"topLeft"
    // });
  } catch (error) {
      Modal.error({
        title: "حدث خطأ ما!",
        content: error?.response?.data?.message,
      });
    } finally {
    dispatch(setFinally());
  }
};


export const register = (values) => async (dispatch) => {
  try {
    dispatch(setRequest());
    const response = await axiosApp.post(`/auth/register`, values);
    dispatch(setSuccess(response.data));
    localStorage.setItem("user", JSON.stringify(response.data.data));
    // notification.success({
    //   message: "تم تسيجيلك بنجاح",
    //   duration: 2,
    //   placement:"topLeft"
    // });
  } catch (error) {
      console.log("🚀 ~ file: index.js:69 ~ register ~ error:", error)
      Modal.error({
        title: "حدث خطأ ما!",
        content: error.response.data.errors,
      });
    } finally {
    dispatch(setFinally());
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.removeItem("user");
    dispatch(setSuccess(''));
  } catch (error) {
      console.log("🚀 ~ file: index.js:69 ~ register ~ error:", error)
    }
};
