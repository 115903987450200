import { createSlice } from "@reduxjs/toolkit";
import { Modal } from "antd";
import axiosApp from "../../api";

// our slice
export const itemSlices = createSlice({
  name: "about",
  initialState: {
    loading: false,
    data:  null,
  },
  reducers: {
    setRequest: (state) => {
      state.loading = true;
    },
    setSuccess: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    setFinally: (state) => {
      state.loading = false;
    },
  },
});

// export the actions
export const { setRequest, setSuccess, setFinally } = itemSlices.actions;

// export the default reducer
export default itemSlices.reducer;

export const policy = () => async (dispatch) => {
  try {
    dispatch(setRequest());
    const response = await axiosApp.get(`/pages/policy` );
    dispatch(setSuccess(response.data.data));
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error?.response?.data?.message,
    });
    } finally {
    dispatch(setFinally());
  }
};
