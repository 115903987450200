import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from './style.module.scss';

const MenuNav = () => {

  const { data } = useSelector((state) => state.home);

  return (
    <ul className={`${styles["menu-nav"]}`}>
      <li>
        <Link to="/" >
         {data?.texts?.value?.home}
        </Link>
      </li>
      {data?.categories?.value?.map((item , index) => (
        <li key={index}>
          <Link to={`/categories/${item?.id}`} >
            {item?.name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default MenuNav;
