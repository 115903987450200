import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { category } from "../../store/Category";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Helmet } from "react-helmet";

const Categories = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, loading } = useSelector((state) => state.category);
  const { data:dataHome } = useSelector((state) => state.home);
  useEffect(() => {
    dispatch(category(id));
  }, [dispatch, id]);

  return (
    <>
      {!!dataHome && !!data ? (
        <Helmet>
          <title>
            {dataHome?.title?.value} | {data?.category?.name}
          </title>
        </Helmet>
      ) : null}

      <section className="section">
        <div className="container">
          {loading ? (
            <div className="divSkeleton">
              <Row className="mb-10">
                <Col lg={6}>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton height={50} count={1} />
                  </SkeletonTheme>
                </Col>
              </Row>
              <Row gutter={30}>
                {[1, 2, 3, 4].map((item, index) => (
                  <Col lg={6} key={index}>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton height={200} count={1} />
                    </SkeletonTheme>
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <>
              <Row className="mb-50" justify="center">
                <Col span={24}>
                  <h2 className="section-title font-bold">
                    {data?.category?.name}
                  </h2>
                </Col>
              </Row>
              <Row gutter={30}>
                {data?.data?.length === 0 ? (
                  <h2 className="text-center">لا توجد نتائح</h2>
                ) : (
                  <>
                    {data?.data?.map((item, index) => (
                      <Col span={24} md={8} lg={6} key={index}>
                        <Link
                          to={`/video/${item?.id}`}
                          className="widget__item-video"
                          key={index}
                        >
                          <div className="widget__item-image">
                            <img src={item?.image} alt="" />
                          </div>
                          <div className="widget__item-content">
                            <h4 className="widget__item-name font-bold">
                              {item?.name}
                            </h4>
                          </div>
                        </Link>
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Categories;
