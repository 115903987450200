import { configureStore } from '@reduxjs/toolkit';

import homeReducer from './Home';
import aboutReducer from './About';
import contactReducer from './Contact';
import categoryReducer from './Video';
import videoReducer from './Video';
import policyReducer from './Policy';
import UserReducer from './Login';
import loginReducer from './Login';

const store = configureStore({
  reducer: {
    home: homeReducer,
    about: aboutReducer,
    contact: contactReducer,
    category: categoryReducer,
    video: videoReducer,
    policy: policyReducer,
    login: loginReducer,
    user: UserReducer,
  },
});

export default store;