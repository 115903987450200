import axios from "axios";
import i18next from "i18next";


const axiosApp = axios.create({
  baseURL: "https://deya.content-dev.com/api/v1",
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accept: "Application/json",
    "Content-type": "multipart/form-data",
    
  },
});

axiosApp.interceptors.request.use((config) => {
  const user = !!localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : {};

  if (Object?.keys(user)?.length > 0) {
    config.headers["Authorization"] = `Bearer ${user.api_token}`;
  }

  if (i18next.language) {
    config.headers["Accept-Language"] = i18next.language;
  }
  return config;
});

export default axiosApp;
