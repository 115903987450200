import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import i18next from "i18next";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Home = () => {
  const { data, loading } = useSelector((state) => state.home);

  return (
    <>
     {!!data ? (
        <Helmet>
          <title>
            {data?.title?.value}
          </title>
        </Helmet>
      ) : null}
        {loading ? (
          <div className="container">
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
              <Skeleton height={300} count={1} />
            </SkeletonTheme>
            {[1, 2, 3].map((item, index) => (
              <div key={index} className="divSkeleton">
                <Row className="mb-10">
                  <Col lg={6}>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton height={50} count={1} />
                    </SkeletonTheme>
                  </Col>
                </Row>
                <Row gutter={30} >
                  {[1, 2, 3,4].map((item, index) => (
                    <Col lg={6} key={index}>
                      <SkeletonTheme baseColor="#202020" highlightColor="#444">
                        <Skeleton height={200} count={1} />
                      </SkeletonTheme>
                    </Col>
                    ))}
                </Row>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="section-image">
              <img src={data?.section1_image?.value} alt="" />
            </div>
            <div className="container">

            {data?.categories?.value?.map((item , index) => (
              <>{item?.lessons?.length === 0 ? null: <section className="section" key={index}>
              <Row className="mb-50" justify="center">
                <Col span={24}>
                  <h2 className="section-title">
                    <Link to={`/categories/${item?.id}`}>
                      <span className="section-name">{item?.name}</span>
                      <span className="section-more"> المزيد </span>
                    </Link>
                  </h2>
                </Col>
              </Row>
              <Row gutter={30} className="justify-content-center">
                <Col span={24}>
                  <Swiper
                    className="swiper-feedbacks"
                    // dir={i18next.language === "ar" ? "rtl" : "ltr"}
                    key={i18next.language}
                    spaceBetween={20}
                    slidesPerView={1.3}
                    navigation={true}
                    modules={[Navigation, Pagination]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1.3,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2.3,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      },
                    }}
                  >
                    {item?.lessons?.map((item, index) => (
                      <SwiperSlide key={index}>
                        <Link
                          to={`/video/${item?.id}`}
                          className="widget__item-video"
                        >
                          <div className="widget__item-image">
                          <LazyLoadImage
                            loading='lazy'
                            effect='blur'
                            alt={item?.image}
                            src={item?.image} // use normal <img> attributes as props
                            />
                            {/* <img src={item?.image} alt="" /> */}
                          </div>
                          <div className="widget__item-content">
                            <h4 className="widget__item-name font-bold">
                              {item?.name}
                            </h4>
                          </div>
                        </Link>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Col>
              </Row>
            </section> }</>
              

            ))}
            </div>
          </>
        )}
    </>
  );
};

export default Home;
