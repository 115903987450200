import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEn from './locales/en/translation.json';
import translationAr from './locales/ar/translation.json';
import axiosApp from "../api";
import i18next from "i18next";

export const resources = {
  en: {
    translations: translationEn,
  },
  ar: {
    translations: translationAr,
  },
};
const response = await axiosApp.get(`/home/index`)
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: response?.data?.locales?.value,
    fallbackLng: "ar",
    lng: i18next.language,
    ns: ["translations"],
    defaultNS: "translations",
    resources,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      // order: ["path", "localStorage", "htmlTag"],
      // caches: ["localStorage"],
    },
    keySeparator: false,
    react: { useSuspense: true },
  });

  export default i18n;