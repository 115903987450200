import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import whatsapp from "../../assets/images/whatsapp.svg";
import twitter from "../../assets/images/twitter.svg";
import instagram from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/facebook.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  const { data } = useSelector((state) => state.home);

  return (
    <>
      <footer className="main-footer">
        <div className="container">
          <Row>
            <Col span={24} lg={6}>
              <img className="logo-footer" src={`${data?.logo?.value}`} alt="" />
              <p>{data?.description?.value}</p>
            </Col>
            <Col span={24} lg={6}>
              <h3 className="title-footer">{data?.texts?.value?.menu}</h3>
              <ul className="link-footer">
                <li>
                  <Link to="/about">{data?.texts?.value?.about}</Link> 
                </li>
                <li>
                  <Link to="/policy">{data?.texts?.value?.policy}</Link> 
                </li>
                <li>
                  <Link to="/contact-us">{data?.texts?.value?.contact_us}</Link>
                </li>
                <li>
                  <Link to="/faq">{data?.texts?.value?.faq}</Link>
                </li>
              </ul>
            </Col>
            <Col span={24} lg={6}>
              <h3 className="title-footer">{data?.texts?.value?.contact_us}</h3>
              <ul className="link-footer">
                <li>
                  <a target="_blank" href={`https://mail.google.com/mail/?view=cm&fs=1&to=${data?.email?.value}`}>{data?.email?.value}</a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      const url = `http://api.whatsapp.com/send?phone=${data?.whatsapp_mobile?.value}`;
                      window.open(url);
                    }}
                  >
                    {data?.whatsapp_mobile?.value}
                  </a>
                </li>
                <li>
                  <a>
                    {data?.phone?.value}
                  </a>
                </li>
              </ul>
            </Col>
            
            <Col span={24} lg={6}>
              <h3 className="title-footer">{data?.texts?.value?.contact_us}</h3>
              <ul className="social-media">
                {data?.social_media?.value?.map((item,index) => (
                  <li className={item?.key} key={index}>
                    <a href={item.link} className="widget__item-media">
                      <div className="widget__item-icon">
                        <img
                          className="me-2"
                          src={
                            item.key === "whatsapp"
                              ? whatsapp
                              : item.key === "twitter"
                              ? twitter
                              : item.key === "instagram"
                              ? instagram
                              : item.key === "facebook"
                              ? facebook
                              : item.key === "facebook"
                              ? facebook
                              : item.key === "facebook"
                              ? facebook
                              : null
                          }
                          alt=""
                        />
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      </footer>
      <div className="footer-bootom">
        <p className="text-center">{data?.texts?.value?.copyright}</p>
      </div>
    </>
  );
};

export default Footer;
