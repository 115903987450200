import { Col, Row, Collapse } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const Faq = () => {
  const { data } = useSelector((state) => state.home);

  return (
    <>
      {!!data ? (
        <>
          <Helmet>
            <title>
              {data?.title?.value} | {data?.texts?.value?.faq}
            </title>
          </Helmet>
          <section className="section">
            <div className="container">
              <Row className="mb-50" justify="center">
                <Col span={24} lg={20}>
                  <h2 className="section-title font-bold">
                    {data?.texts?.value?.faq}
                  </h2>
                </Col>
              </Row>
              <Row className="mb-50" justify="center">
                <Col span={24} lg={20}>
                  {data?.faqs?.value.length > 0 ? (
                    <Collapse
                      items={data?.faqs?.value?.map(
                        (item) =>
                          ({
                            value: item.id,
                            label: item.name,
                            children: <p>{item.description}</p>,
                          } || [])
                      )}
                    />
                  ) : (
                    <h2>لا توجد بيانات</h2>
                  )}
                </Col>
              </Row>
            </div>
          </section>
        </>
      ) : null}
    </>
  );
};

export default Faq;
