import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { policy } from "../../store/Policy";
import { Helmet } from "react-helmet";
import i18next from "i18next";

const Policy = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.policy);
  const { data:dataHome} = useSelector((state) => state.home);
  useEffect(() => {
    dispatch(policy());
  }, [dispatch, i18next.language]);


  return (
    <>
    {!!dataHome && !!data ? (
        <Helmet>
          <title>
            {dataHome?.title?.value} | {data?.name}
          </title>
        </Helmet>
      ) : null}
      <section className="section animated">
        <div className="container">
          <Row className="mb-50" justify="center">
            <Col span={24}>
              <h2 className=" font-bold section-title">
                {data?.name}
              </h2>
            </Col>
          </Row>
          <Row className="mb-50" justify="center">
            <Col span={24}>
              <h4 dangerouslySetInnerHTML={{__html: data?.description}}></h4>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Policy;
