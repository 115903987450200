import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { video } from "../../store/Video";
import Vimeo from "@u-wave/react-vimeo";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
const Video = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, loading } = useSelector((state) => state.video);
  useEffect(() => {
    dispatch(video(id));
    // var iframe = document.getElementsByTagName("iframe")[0]
    // console.log("🚀 ~ file: index.jsx:15 ~ useEffect ~ iframe:", iframe)
    // var elmnt = iframe.contentWindow.document.queryselector('.vp-title');
    // elmnt.style.display = "none";
  }, [dispatch , id]);



  return (
    <>
    
      {loading ? (
       <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Skeleton height={300} count={1} />
      </SkeletonTheme>
      ) : (
        <section className="section-hero">
          <div className="hero-container">
            <div className="info-container">
              <div className="info-title">{data?.name}</div>
              <div className="info-desc">{data?.description}</div>
            </div>
            <div className="hero-image-container">
              <div
                className="hero-image"
                style={{ background: `url(${data?.image})` }}
              ></div>
            </div>
          </div>
          <div className="contianer">
            {loading ? (
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                  <Skeleton height={300} count={1} />
                </SkeletonTheme>
                ) : data?.link? <Vimeo className="21111111"
                autoplay={false}
                responsive={true}
                style={{ justifyContent: "center" }}
                start={0}
                video={data?.link}
              /> : null
            }
          </div>
        </section>
      )}
    </>
  );
};

export default Video;
