import { Button, Col, Form, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { contactPost } from "../../store/Contact";
import check from "../../assets/images/check-circle.svg";
import { Helmet } from "react-helmet";

const Contact = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.contact);
  const { data } = useSelector((state) => state.home);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = (values) => {
    dispatch(
      contactPost({
        values,
        callback: () => {
          form.resetFields();
          setIsModalOpen(true);
        },
      })
    );
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {!!data ? (
        <Helmet>
          <title>
            {data?.title?.value} | {data?.texts?.value?.contact_us}
          </title>
        </Helmet>
      ) : null}

      <section className="section animated">
        <div className="container">
          <Row justify="center">
            <Col span={18}>
              <h2 className="text-center font-bold section-title">
                {data?.texts?.value?.contact_us}
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={18}>
              <Form
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                size="large"
              >
                <Row gutter={20}>
                  <Col span={24} lg={24}>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: t("The_field_is_required"),
                        },
                      ]}
                    >
                      <Input placeholder={t("name")} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={12}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: t("The_field_is_required"),
                        },
                        {
                          type: "email",
                          message: t("enter_valid_email"),
                        },
                      ]}
                    >
                      <Input placeholder={t("email")} />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={12}>
                    <Form.Item
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: t("The_field_is_required"),
                        },
                        {
                          pattern: /^(?:\d*)$/,
                          message: t("please_enter_valid_mobile_number"),
                        },
                      ]}
                    >
                      <Input placeholder={t("mobile")} />
                    </Form.Item>
                  </Col>
                </Row>

                <Col span={24}>
                  <Form.Item
                    name="content"
                    rules={[
                      {
                        required: true,
                        message: t("The_field_is_required"),
                      },
                    ]}
                  >
                    <Input.TextArea rows={6} placeholder={t("content")} />
                  </Form.Item>
                </Col>
                <Form.Item>
                  <Button
                    loading={loading === "post"}
                    type="primary"
                    block
                    htmlType="submit"
                  >
                    {t("submit")}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </section>
      <Modal
        title={false}
        footer={false}
        open={isModalOpen}
        onCancel={handleCancel}
        className="modal-success"
      >
        <div className="text-center content-success">
          <img src={check} alt="Ceck" className="mb-30" />
          <h3 className="font-bold mb-30">{t("ticket_successfully")}</h3>
          <Button type="primary" size="large" onClick={handleCancel}>
            موافق
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Contact;
