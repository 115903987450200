import { Button, Col, Drawer, Dropdown, Menu, Modal, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import MenuNav from "./MenuNav";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import styles from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { home } from "../../store/Home";
import { FaChevronDown } from "react-icons/fa";
import { logout } from "../../store/Login";

const Header = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [state, setState] = useState(false);
  const { data , loading } = useSelector((state) => state.home);
  const { user } = useSelector((state) => state.login);

  const showDrawer = () => {
    setState(!state);
  };
  const onClose = () => {
    setState(false);
  };

  useEffect(() => {
    dispatch(home());
  }, [dispatch , i18next.language]);


  function handleMenuClick(e) {
    i18next.changeLanguage(e.key);
    // window.location.reload();
  }

  const langItems = [
    
  ];
  if(data?.locales?.value){
    Object.keys(data?.locales?.value).forEach(function(key) {
      langItems?.push({label: key,key: key});
    });
  }
  const langMenu = <Menu onClick={handleMenuClick} items={langItems} />;

  const selectedLang = langItems.find((item) => item?.key === i18next.language);


  useEffect(() => {
    if(localStorage?.getItem("i18nextLng") === "en-US"){
      i18next.changeLanguage("ar");
      localStorage.setItem("i18nextLng", "ar");
    }
    if(i18next.language === "ar"){
      document.documentElement.setAttribute("dir", "rtl");
    }else{
      document.documentElement.setAttribute("dir", "ltr");
    }
  }, [i18next.language]);
  
  
  const items = [
    {
      key: '1',
      label: (
        <Link onClick={() => confirm()} >
          تسجيل
        </Link>
      ),
    },
  ];

  const confirm = () => {
    Modal.confirm({
      title: "هل تريد تسجيل الخروج",
      okText: "موافق",
      cancelText: "الغاء",
      onOk: () => {
        dispatch(logout())
        // localStorage.removeItem("user");
      },
    });
  };


  return (
    <>
    {loading ? <div className="loader-page"><span></span><span></span></div> : null}
    <div className={`${styles["main-header"]}`}>
      <div className="container">
        <Row align="middle" justify="space-between">
          <Col span={4} lg={3}>
            <Link to="/">
              <img src={data?.logo?.value} alt="" className={`${styles["logo"]}`} />
            </Link>
          </Col>
          <Col span={20} className={`${styles["menu"]}`}>
            <div className={`d-flex ${styles["container-menu"]}`}>
              <div className={`${styles["display-mobile-none"]}`} ><MenuNav /></div>
              <div className="d-flex align-items-center">
                <Dropdown
                  placement="bottomLeft"
                  overlay={langMenu}
                  trigger={["click"]}
                >
                  <Button >
                    <Typography.Text
                      title={t("SelectLanguage")}
                      strong
                      style={{ textTransform: "uppercase" }}
                      type="secondary"
                      >
                      {selectedLang?.key}
                    </Typography.Text>
                  </Button>
                </Dropdown>
                <div className={`${styles["auth"]}`}>
                  {Object?.keys(user)?.length > 0 ? 
                  <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      {user?.data ? user?.data?.account?.name : user?.account?.name}
                      <FaChevronDown />
                    </Space>
                  </a>
                </Dropdown> : <div className={`d-flex align-items-center ${styles["auth-btn"]}`}>
                    <Link to="/login" className={`${styles["login"]}`}>{data?.texts?.value?.login}</Link>  
                    <Link to="/register">{data?.texts?.value?.register}</Link>  
                  </div>}
                </div>
                <div  className={`${styles["menuCon"]}`}>
                  <div className="barsMenu" onClick={showDrawer}>
                    <span className={`${styles["barsBtn"]}`}></span>
                  </div>
                  <Drawer
                    width={250}
                    title={false}
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    visible={state}
                    >
                    <div
                      onClick={showDrawer}
                      className={`${styles["close"]}`}
                      ></div>
                    <div className="mt-30">
                      <MenuNav />
                    </div>
                  </Drawer>
                </div>
              </div>
            </div>
          </Col>
          <Col span={3} className={`${styles["menu-mobile"]}`}>
            
          </Col>
        </Row>
      </div>
    </div>
    </>
  );
};

export default Header;
