import React from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import { MdLock  } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import styles from './style.module.scss'
import { register } from '../../../store/Login';
import { Helmet } from 'react-helmet';

const Register = () => {

  const dispatch = useDispatch();
  const { loading  , user } = useSelector((state) => state.login);
  const { data  } = useSelector((state) => state.home);
  const onFinish = (values) => {
    dispatch(register(values))
  };
  

  if (Object?.keys(user)?.length > 0) {
    return <Navigate to="/" />;
  }

  return (
    <>
    {!!data ? (
      <Helmet>
        <title>
          {data?.title?.value} | {data?.texts?.value?.register}
        </title>
      </Helmet>
    ) : null}
    <div className={`${styles["section-login"]}`}>
      <div className='container'>
        <Row className="mb-50" justify="center">
          <Col span={20}>
            <h2 className="text-center font-bold section-title mb-30">
            {data?.texts?.value?.register} 
            </h2>
          </Col>
        </Row>
        <Row gutter={20} align="middle" justify="center">
          <Col span={24} lg={11}>
            <Form size="large"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              onFinish={onFinish}
              autoComplete="off"
              >
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: data?.texts?.value?.required ,
                  },
                ]}
                >
                <Input placeholder={data?.texts?.value?.name} />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message:data?.texts?.value?.required ,
                  },
                  {
                    type: "email",
                    message:data?.texts?.value?.valid_email ,
                  },
                ]}
                >
                <Input placeholder={data?.texts?.value?.email} />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: data?.texts?.value?.required ,
                  },
                  // { min: 8, message: 'Username must be minimum 5 characters.' },
                ]}
                >
                <Input.Password prefix={<MdLock />}  placeholder={data?.texts?.value?.password} />
              </Form.Item>

              {/* <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
                >
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}

              <Form.Item>
                <Button loading={loading} block type="primary" htmlType="submit">
                {data?.texts?.value?.register}  
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
              </>
  )
}

export default Register