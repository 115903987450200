import { Routes, Route  } from "react-router-dom";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Contact from "../Pages/Contact";
import Categories from "../Pages/Categories";
import Video from "../Pages/Video";
import Policy from "../Pages/Policy";
import Faq from "../Pages/Faq";
import Login from "../Pages/Auth/Login";
import Register from "../Pages/Auth/register";

const Routers = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} /> 
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/about" element={<About />} /> 
      <Route path="/policy" element={<Policy />} /> 
      <Route path="/faq" element={<Faq />} /> 
      <Route path="/contact-us" element={<Contact />} /> 
      <Route path="/categories/:id" element={<Categories />} /> 
      <Route path="/video/:id" element={<Video />} /> 
    </Routes>
  );
};

export default Routers;
